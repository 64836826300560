/* stylelint-disable scss/no-duplicate-dollar-variables */
/* stylelint-enable */
.psv-panel {
  position: absolute;
  z-index: 90;
  right: 0;
  height: 100%;
  width: 400px;
  max-width: calc(100% - 24px);
  background: rgba(10, 10, 10, 0.7);
  transform: translate3d(100%, 0, 0);
  opacity: 0;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  transition-duration: .1s;
  cursor: default;
  margin-left: 9px; }
  .psv--has-navbar .psv-panel {
    height: calc(100% - 40px); }
  .psv-panel-close-button {
    display: none;
    position: absolute;
    top: 0;
    left: -24px;
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.9); }
    .psv-panel-close-button::before, .psv-panel-close-button::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 4px;
      width: 15px;
      height: 1px;
      background-color: #fff;
      transition: .2s ease-in-out;
      transition-property: width, left, transform; }
    .psv-panel-close-button::before {
      transform: rotate(45deg); }
    .psv-panel-close-button::after {
      transform: rotate(-45deg); }
    .psv-panel-close-button:hover::before, .psv-panel-close-button:hover::after {
      left: 0;
      width: 23px; }
    .psv-panel-close-button:hover::before {
      transform: rotate(135deg); }
    .psv-panel-close-button:hover::after {
      transform: rotate(45deg); }
  .psv-panel-resizer {
    display: none;
    position: absolute;
    top: 0;
    left: -9px;
    width: 9px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    cursor: col-resize; }
    .psv-panel-resizer::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 1px;
      margin-top: -14.5px;
      width: 1px;
      height: 1px;
      box-shadow: 1px 0 #fff, 3px 0px #fff, 5px 0px #fff, 1px 2px #fff, 3px 2px #fff, 5px 2px #fff, 1px 4px #fff, 3px 4px #fff, 5px 4px #fff, 1px 6px #fff, 3px 6px #fff, 5px 6px #fff, 1px 8px #fff, 3px 8px #fff, 5px 8px #fff, 1px 10px #fff, 3px 10px #fff, 5px 10px #fff, 1px 12px #fff, 3px 12px #fff, 5px 12px #fff, 1px 14px #fff, 3px 14px #fff, 5px 14px #fff, 1px 16px #fff, 3px 16px #fff, 5px 16px #fff, 1px 18px #fff, 3px 18px #fff, 5px 18px #fff, 1px 20px #fff, 3px 20px #fff, 5px 20px #fff, 1px 22px #fff, 3px 22px #fff, 5px 22px #fff, 1px 24px #fff, 3px 24px #fff, 5px 24px #fff, 1px 26px #fff, 3px 26px #fff, 5px 26px #fff, 1px 28px #fff, 3px 28px #fff, 5px 28px #fff;
      background: transparent; }
  .psv-panel-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    color: gainsboro;
    font: 16px sans-serif;
    overflow: auto; }
    .psv-panel-content:not(.psv-panel-content--no-margin) {
      padding: 1em; }
    .psv-panel-content--no-interaction {
      user-select: none;
      pointer-events: none; }
  .psv-panel--open {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition-duration: .2s; }
    .psv-panel--open .psv-panel-close-button,
    .psv-panel--open .psv-panel-resizer {
      display: block; }
