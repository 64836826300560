.psv-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background: radial-gradient(#fff 0%, #fdfdfd 16%, #fbfbfb 33%, #f8f8f8 49%, #efefef 66%, #dfdfdf 82%, #bfbfbf 100%);
  overflow: hidden; }

.psv-container--fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.psv-canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: opacity linear 100ms; }

.psv-canvas {
  display: block; }
