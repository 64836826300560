.psv-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 110;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: radial-gradient(#fff 0%, #fdfdfd 16%, #fbfbfb 33%, #f8f8f8 49%, #efefef 66%, #dfdfdf 82%, #bfbfbf 100%);
  opacity: 0.8; }
  .psv-overlay-image {
    margin-bottom: 4vh; }
    .psv-overlay-image svg {
      width: 50vw; }
      @media (orientation: landscape) {
        .psv-overlay-image svg {
          /* stylelint-disable-line scss/media-feature-value-dollar-variable */
          width: 25vw; } }
  .psv-overlay-text {
    font: 30px sans-serif;
    text-align: center; }
  .psv-overlay-subtext {
    font: 20px sans-serif;
    opacity: .8;
    text-align: center; }
