.psv-notification {
  position: absolute;
  z-index: 100;
  bottom: 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0 2em;
  opacity: 0;
  transition-property: opacity, bottom;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms; }
  .psv-notification-content {
    max-width: 50em;
    background-color: rgba(61, 61, 61, 0.8);
    border-radius: 4px;
    padding: 0.5em 1em;
    font: 14px sans-serif;
    color: white; }
  .psv-notification--visible {
    opacity: 100;
    bottom: 80px; }
