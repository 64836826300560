.psv-navbar {
  display: flex;
  position: absolute;
  z-index: 90;
  bottom: -40px;
  left: 0;
  width: 100%;
  height: 40px;
  background: rgba(61, 61, 61, 0.5);
  transition: bottom ease-in-out .1s; }
  .psv-navbar--open {
    bottom: 0; }
  .psv-navbar,
  .psv-navbar * {
    box-sizing: content-box; }

.psv-caption {
  flex: 1 1 100%;
  color: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  text-align: center; }
  .psv-caption-icon {
    height: 20px;
    width: 20px;
    cursor: pointer; }
    .psv-caption-icon * {
      fill: rgba(255, 255, 255, 0.7); }
  .psv-caption-content {
    display: inline-block;
    padding: 10px;
    font: 16px sans-serif;
    white-space: nowrap; }

.psv-button {
  flex: 0 0 auto;
  padding: 10px;
  position: relative;
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: transparent;
  color: rgba(255, 255, 255, 0.7); }
  .psv-button--active {
    background: rgba(255, 255, 255, 0.2); }
  .psv-button--disabled {
    pointer-events: none;
    opacity: 0.5; }
  .psv-button .psv-button-svg {
    width: 100%;
    transform: scale(1);
    transition: transform 200ms ease; }
    .psv-button .psv-button-svg * {
      fill: currentColor; }

.psv-container:not(.psv--is-touch) .psv-button--hover-scale:not(.psv-button--disabled):hover .psv-button-svg {
  transform: scale(1.2); }
