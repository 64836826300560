.psv-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80; }

.psv-loader {
  position: relative;
  text-align: center;
  color: rgba(61, 61, 61, 0.7);
  width: 150px;
  height: 150px;
  border: 10px solid transparent; }
  .psv-loader::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }
  .psv-loader, .psv-loader-image, .psv-loader-text {
    display: inline-block;
    vertical-align: middle; }
  .psv-loader-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .psv-loader-text {
    font: 14px sans-serif; }
