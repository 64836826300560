.psv-zoom-range.psv-button {
  width: 80px;
  height: 1px;
  margin: 10px 0;
  padding: 9.5px 0;
  max-width: 600px; }

.psv-zoom-range-line {
  position: relative;
  width: 80px;
  height: 1px;
  background: rgba(255, 255, 255, 0.7);
  transition: all .3s ease; }

.psv-zoom-range-handle {
  position: absolute;
  border-radius: 50%;
  top: -3px;
  width: 7px;
  height: 7px;
  background: rgba(255, 255, 255, 0.7);
  transform: scale(1);
  transition: transform .3s ease; }

.psv-zoom-range:not(.psv-button--disabled):hover .psv-zoom-range-line {
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.7); }

.psv-zoom-range:not(.psv-button--disabled):hover .psv-zoom-range-handle {
  transform: scale(1.3); }
