.psv-tooltip {
  position: absolute;
  z-index: 50;
  box-sizing: border-box;
  max-width: 200px;
  background-color: rgba(61, 61, 61, 0.8);
  border-radius: 4px;
  padding: 0.5em 1em;
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 100ms;
  outline: 5px solid transparent; }
  .psv-tooltip-content {
    color: white;
    font: 14px sans-serif;
    text-shadow: 0 1px #000; }
  .psv-tooltip-arrow {
    position: absolute;
    height: 0;
    width: 0;
    border: 7px solid transparent; }
  .psv-tooltip--bottom-center {
    box-shadow: 0 3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, -5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--bottom-center .psv-tooltip-arrow {
      border-bottom-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--center-left {
    box-shadow: -3px 0 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(5px, 0, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--center-left .psv-tooltip-arrow {
      border-left-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--top-center {
    box-shadow: 0 -3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, 5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--top-center .psv-tooltip-arrow {
      border-top-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--center-right {
    box-shadow: 3px 0 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(-5px, 0, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--center-right .psv-tooltip-arrow {
      border-right-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--bottom-left {
    box-shadow: -3px 3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, -5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--bottom-left .psv-tooltip-arrow {
      border-bottom-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--bottom-right {
    box-shadow: 3px 3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, -5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--bottom-right .psv-tooltip-arrow {
      border-bottom-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--top-left {
    box-shadow: -3px -3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, 5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--top-left .psv-tooltip-arrow {
      border-top-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--top-right {
    box-shadow: 3px -3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, 5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--top-right .psv-tooltip-arrow {
      border-top-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--visible {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition-duration: 100ms; }
